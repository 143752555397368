<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Укажите все части основного диагноза
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="answered"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
              >
                <div
                  v-if="radioTabs.length == 5"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/green_check2.png')
                    "
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs.length !== 5"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/red_warning.png')
                    "
                  />
                  Неверный ответ!
                </div>
                <div class="text-2 mb-2">
                  <b>Пациентка имеет помимо перечисленных заболеваний:</b>
                </div>
                <div class="text-2">
                  <ul>
                    <li v-if="!radioTabs.includes('test10_1_1')">
                      Гипертоническая болезнь III cтадии, 2 степени, риск 4.
                      Контролируемая АГ. Целевое АД &lt;130/&lt;80 мм рт. ст.
                    </li>
                    <li v-if="!radioTabs.includes('test10_1_2')">Ожирение I степени</li>
                    <li v-if="!radioTabs.includes('test10_1_3')">
                      Гиперлипидемия IIb типа
                    </li>
                    <li v-if="!radioTabs.includes('test10_1_4')">
                      ХБП С3А стадии, альбуминурия А0
                    </li>
                    <li v-if="!radioTabs.includes('test10_1_5')">
                      Ишемическая болезнь сердца: Стенокардия напряжения I фк.,
                      атеросклероз коронарных артерий.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <Checkbox
                class="mt-6 mb-2"
                :inputValue="'test10_1_1'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Гипертоническая болезнь III cтадии, 2 степени, риск 4. Контролируемая АГ. Целевое АД <130/<80 мм рт. ст.'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test10_1_2'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Ожирение I степени'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test10_1_3'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Гиперлипидемия IIb типа'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test10_1_4'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'ХБП С3А стадии, альбуминурия А0'"
              />
              <Checkbox
                class="mb-6"
                :inputValue="'test10_1_5'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Ишемическая болезнь сердца: Стенокардия напряжения I фк., атеросклероз коронарных  артерий.'"
              />
              <div
                @click="answerTest"
                :class="{ button_disabled: !radioTabs.length }"
                v-if="!answered"
                class="button button_pink-light px-6 mb-4"
              >
                Ответить
              </div>
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${!answered ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
            >
              <div
                v-if="radioTabs.length == 5"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/green_check2.png')
                  "
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs.length !== 5"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/red_warning.png')
                  "
                />
                Неверный ответ!
              </div>
              <div class="text-2 mb-2">
                <b>Пациентка имеет помимо перечисленных заболеваний:</b>
              </div>
              <div class="text-2">
                <ul>
                  <li v-if="!radioTabs.includes(0)">
                    Гипертоническая болезнь III cтадии, 2 степени, риск 4.
                    Контролируемая АГ. Целевое АД &lt;130/&lt;80 мм рт. ст.
                  </li>
                  <li v-if="!radioTabs.includes(1)">Ожирение I степени</li>
                  <li v-if="!radioTabs.includes(2)">Гиперлипидемия IIb типа</li>
                  <li v-if="!radioTabs.includes(3)">
                    ХБП С3А стадии, альбуминурия А0
                  </li>
                  <li v-if="!radioTabs.includes(4)">
                    Ишемическая болезнь сердца: Стенокардия напряжения I фк.,
                    атеросклероз коронарных артерий.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :disableNext="!answered"
        :stepsCount="18"
        :activeStep="10"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import Checkbox from "@/components/interactiveClinicalCase/Checkbox.vue";
export default {
  name: "ICCStep12",
  components: {
    Steps,
    Checkbox,
  },
  props: {
    step: Object
  },
  data: () => ({
    radioTabs: [],
    answered: false,
  }),
  methods: {
    answerTest() {
      this.answered = true;
      if (
        !this.step.test.questions[0].is_answered && this.radioTabs
      ) {
         let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: this.radioTabs,
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
  },
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices.filter(
      (a) => a.is_selected
    ).map((a) => a.slug)
    if(this.radioTabs.length) {
      this.answered = true
    }
  },
  watch: {
    radioTabs() {
      
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>