<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Какая высота атеросклеротической бляшки при оценке брахиоцефальных
              артерий с помощью УЗИ повлияет на дальнейшую тактику наблюдения за
              пациентом?
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
              >
                <div class="title-1 mb-4">Правильный ответ – более 1,5 мм</div>
                <div class="text-2 mb-4">
                  <div class="mb-2">
                    Согласно консенсусу российских экспертов, при отсутствии
                    атеросклеротической бляшки (АСБ) в сонных и бедренных
                    артериях пациенту должно быть рекомендовано повторное УЗИ
                    через 3 года.
                  </div>
                  <div class="mb-2">
                    При наличии АСБ высотой &lt;1,5 мм и максимальном стенозе
                    &lt;25% в сонных артериях, а также при максимальном стенозе
                    &lt;25% в бедренных артериях повторное УЗИ артерий следует
                    провести через 2 года.
                  </div>
                  <div>
                    При АСБ высотой ≥1,5 мм и максимальном стенозе &lt;50% в
                    сонных артериях, а также при максимальном стенозе 25-49% в
                    бедренных артериях повторное УЗИ рекомендуется проводить
                    через 1 год, при отсутствии динамики атеросклеротического
                    процесса частоту исследований можно сократить до 1
                    исследования в 2–3 года.<sup>7</sup>
                  </div>
                </div>
                <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                  <i
                    >Источник: Нарушения липидного обмена. Клинические
                    рекомендации 2023 / М.В. Ежов, В.В. Кухарчук, И.В. Сергиенко
                    [и др.] // Российский кардиологический журнал – 2023. –
                    Т.28. – № 5. – С.5471.</i
                  >
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test9_1_1'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'0,5 мм'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test9_1_2'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'1,0 мм'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test9_1_3'"
                v-model="radioTabs"
                :success="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'1,5 мм'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test9_1_4'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Более 1,5 мм'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="
              icc__block-white-plate icc__block-white-plate_mobile
              d-none d-xl-flex
            "
            :style="`opacity: ${radioTabs == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs != null"
            >
              <div class="title-1 mb-4">Правильный ответ – более 1,5 мм</div>
              <div class="text-2 mb-4">
                <div class="mb-2">
                  Согласно консенсусу российских экспертов, при отсутствии
                  атеросклеротической бляшки (АСБ) в сонных и бедренных артериях
                  пациенту должно быть рекомендовано повторное УЗИ через 3 года.
                </div>
                <div class="mb-2">
                  При наличии АСБ высотой &lt;1,5 мм и максимальном стенозе
                  &lt;25% в сонных артериях, а также при максимальном стенозе
                  &lt;25% в бедренных артериях повторное УЗИ артерий следует
                  провести через 2 года.
                </div>
                <div>
                  При АСБ высотой ≥1,5 мм и максимальном стенозе &lt;50% в
                  сонных артериях, а также при максимальном стенозе 25-49% в
                  бедренных артериях повторное УЗИ рекомендуется проводить через
                  1 год, при отсутствии динамики атеросклеротического процесса
                  частоту исследований можно сократить до 1 исследования в 2–3
                  года.<sup>7</sup>
                </div>
              </div>
              <div class="text-3 mt-auto mb-sm-4" style="color: #3c4242">
                <i
                  >Источник: Нарушения липидного обмена. Клинические
                  рекомендации 2023 / М.В. Ежов, В.В. Кухарчук, И.В. Сергиенко
                  [и др.] // Российский кардиологический журнал – 2023. – Т.28.
                  – № 5. – С.5471.</i
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :disableNext="radioTabs == null"
        :stepsCount="18"
        :activeStep="9"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
import Select from "@/components/interactiveClinicalCase/Select.vue";
export default {
  name: "ICCStep10",
  components: {
    Steps,
    RadioButton,
    Select,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: null,
  }),
  methods: {
    answerTest() {
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
  },
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices.find(
      (a) => a.is_selected
    )
      ? this.step.test.questions[0].choices.find((a) => a.is_selected).slug
      : null;
  },
  watch: {
    radioTabs() {
      if (!this.step.test.questions[0].is_answered && this.radioTabs) {
         let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          method: "POST",
          params: params,
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: [this.radioTabs],
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";

.icc {
  & .select-form {
    &__select-header {
      margin-bottom: 16px;
      padding: 10px 16px;
      border-radius: 8px;
      background: #f8f8f8;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #3c4242;
    }
  }
}
</style>