<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container mb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="
                  require('@/assets/img/interactive-clinical-case/step6_icon1.svg')
                "
              />
              Завершение работы над клиническим случаем
            </div>
            <div class="icc__block-description text-1">
              <strong>Материалы для скачивания:</strong>
              <a
                href="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/pdf/%D0%9A%D0%BE%D0%BD%D1%82%D1%80%D0%BE%D0%BB%D1%8C%20%D1%82%D0%B5%D1%80%D0%B0%D0%BF%D0%B8%D0%B8%20%D1%81%D1%82%D0%B0%D1%82%D0%B8%D0%BD%D0%B0%D0%BC%D0%B8.pdf"
                target="_blank"
                class="d-flex mt-2 mb-4"
                style="color: #d0006f"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.0002 2C11.735 2 11.4806 2.10536 11.2931 2.29289C11.1055 2.48043 11.0002 2.73478 11.0002 3V13.59L7.71019 10.29C7.52188 10.1017 7.26649 9.99591 7.00019 9.99591C6.73388 9.99591 6.47849 10.1017 6.29019 10.29C6.10188 10.4783 5.99609 10.7337 5.99609 11C5.99609 11.2663 6.10188 11.5217 6.29019 11.71L11.2902 16.71C11.3831 16.8037 11.4937 16.8781 11.6156 16.9289C11.7375 16.9797 11.8682 17.0058 12.0002 17.0058C12.1322 17.0058 12.2629 16.9797 12.3848 16.9289C12.5066 16.8781 12.6172 16.8037 12.7102 16.71L17.7102 11.71C17.8034 11.6168 17.8774 11.5061 17.9278 11.3843C17.9783 11.2624 18.0043 11.1319 18.0043 11C18.0043 10.8681 17.9783 10.7376 17.9278 10.6158C17.8774 10.4939 17.8034 10.3832 17.7102 10.29C17.6169 10.1968 17.5063 10.1228 17.3844 10.0723C17.2626 10.0219 17.132 9.99591 17.0002 9.99591C16.8683 9.99591 16.7378 10.0219 16.6159 10.0723C16.4941 10.1228 16.3834 10.1968 16.2902 10.29L13.0002 13.59V3C13.0002 2.73478 12.8948 2.48043 12.7073 2.29289C12.5198 2.10536 12.2654 2 12.0002 2Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="my-auto ml-2" style="text-decoration: underline">
                  <strong>Контроль терапии статинами</strong>
                </span>
              </a>
              <a
                href="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/pdf/%D0%90%D0%BB%D0%B3%D0%BE%D1%80%D0%B8%D1%82%D0%BC%20%D0%B2%D0%B5%D0%B4%D0%B5%D0%BD%D0%B8%D1%8F%20%D0%BF%D0%B0%D1%86%D0%B8%D0%B5%D0%BD%D1%82%D0%B0%20%D1%81%20%D0%B4%D0%B8%D1%81%D0%BB%D0%B8%D0%BF%D0%B8%D0%B4%D0%B5%D0%BC%D0%B8%D0%B5%D0%B9.pdf"
                target="_blank"
                class="d-flex mt-2 mb-4"
                style="color: #d0006f"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.0002 2C11.735 2 11.4806 2.10536 11.2931 2.29289C11.1055 2.48043 11.0002 2.73478 11.0002 3V13.59L7.71019 10.29C7.52188 10.1017 7.26649 9.99591 7.00019 9.99591C6.73388 9.99591 6.47849 10.1017 6.29019 10.29C6.10188 10.4783 5.99609 10.7337 5.99609 11C5.99609 11.2663 6.10188 11.5217 6.29019 11.71L11.2902 16.71C11.3831 16.8037 11.4937 16.8781 11.6156 16.9289C11.7375 16.9797 11.8682 17.0058 12.0002 17.0058C12.1322 17.0058 12.2629 16.9797 12.3848 16.9289C12.5066 16.8781 12.6172 16.8037 12.7102 16.71L17.7102 11.71C17.8034 11.6168 17.8774 11.5061 17.9278 11.3843C17.9783 11.2624 18.0043 11.1319 18.0043 11C18.0043 10.8681 17.9783 10.7376 17.9278 10.6158C17.8774 10.4939 17.8034 10.3832 17.7102 10.29C17.6169 10.1968 17.5063 10.1228 17.3844 10.0723C17.2626 10.0219 17.132 9.99591 17.0002 9.99591C16.8683 9.99591 16.7378 10.0219 16.6159 10.0723C16.4941 10.1228 16.3834 10.1968 16.2902 10.29L13.0002 13.59V3C13.0002 2.73478 12.8948 2.48043 12.7073 2.29289C12.5198 2.10536 12.2654 2 12.0002 2Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="my-auto ml-2" style="text-decoration: underline">
                  <strong>Алгоритм ведения пациента с дислипидемией</strong>
                </span>
              </a>
              <a
                href="http://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/pdf/%D0%9F%D1%80%D0%BE%D1%84%D0%B8%D0%BB%D0%B0%D0%BA%D1%82%D0%B8%D0%BA%D0%B0%20%D1%80%D0%B5%D1%86%D0%B8%D0%B4%D0%B8%D0%B2%D0%BE%D0%B2%20%D1%84%D0%B8%D0%B1%D1%80%D0%B8%D0%BB%D0%BB%D1%8F%D1%86%D0%B8%D0%B8%20%D0%BF%D1%80%D0%B5%D0%B4%D1%81%D0%B5%D1%80%D0%B4%D0%B8%D0%B9.pdf"
                target="_blank"
                class="d-flex mt-2 mb-4"
                style="color: #d0006f"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 19H3C2.73478 19 2.48043 19.1054 2.29289 19.2929C2.10536 19.4804 2 19.7348 2 20C2 20.2652 2.10536 20.5196 2.29289 20.7071C2.48043 20.8946 2.73478 21 3 21H21C21.2652 21 21.5196 20.8946 21.7071 20.7071C21.8946 20.5196 22 20.2652 22 20C22 19.7348 21.8946 19.4804 21.7071 19.2929C21.5196 19.1054 21.2652 19 21 19Z"
                    fill="currentColor"
                  />
                  <path
                    d="M12.0002 2C11.735 2 11.4806 2.10536 11.2931 2.29289C11.1055 2.48043 11.0002 2.73478 11.0002 3V13.59L7.71019 10.29C7.52188 10.1017 7.26649 9.99591 7.00019 9.99591C6.73388 9.99591 6.47849 10.1017 6.29019 10.29C6.10188 10.4783 5.99609 10.7337 5.99609 11C5.99609 11.2663 6.10188 11.5217 6.29019 11.71L11.2902 16.71C11.3831 16.8037 11.4937 16.8781 11.6156 16.9289C11.7375 16.9797 11.8682 17.0058 12.0002 17.0058C12.1322 17.0058 12.2629 16.9797 12.3848 16.9289C12.5066 16.8781 12.6172 16.8037 12.7102 16.71L17.7102 11.71C17.8034 11.6168 17.8774 11.5061 17.9278 11.3843C17.9783 11.2624 18.0043 11.1319 18.0043 11C18.0043 10.8681 17.9783 10.7376 17.9278 10.6158C17.8774 10.4939 17.8034 10.3832 17.7102 10.29C17.6169 10.1968 17.5063 10.1228 17.3844 10.0723C17.2626 10.0219 17.132 9.99591 17.0002 9.99591C16.8683 9.99591 16.7378 10.0219 16.6159 10.0723C16.4941 10.1228 16.3834 10.1968 16.2902 10.29L13.0002 13.59V3C13.0002 2.73478 12.8948 2.48043 12.7073 2.29289C12.5198 2.10536 12.2654 2 12.0002 2Z"
                    fill="currentColor"
                  />
                </svg>
                <span class="my-auto ml-2" style="text-decoration: underline">
                  <strong>Профилактика рецидивов фибрилляции предсердий</strong>
                </span>
              </a>
            </div>
          </div>
          <div class="icc__block-video">
            <video
              src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/10.mp4"
              :poster="
                require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
              "
              controls
            />
          </div>
        </div>
       
      </div>
       <div class="button button_pink-light px-6 mb-6 mx-auto" @click="refreshClinicalCase">Попробовать еще раз</div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="18"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
export default {
  name: "ICCStep6",
  components: {
    Steps,
  },
  props: {
    step: Object
  },
  methods: {
    refreshClinicalCase() {
      this.$emit('refreshClinicalCase')
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>