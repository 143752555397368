<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Вы можете ознакомиться с результатами исследований, которые
              пациентка взяла с собой:
            </div>
            <div class="d-none d-xl-block">
              <RadioButton
                class="mt-6 mb-2 radiobtn_simple"
                :inputValue="0"
                v-model="radioTabs[0]"
                :label="'Клинический анализ крови'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="1"
                v-model="radioTabs[0]"
                :label="'Общий анализ мочи'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="2"
                v-model="radioTabs[0]"
                :label="'Биохимический анализ крови'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="3"
                v-model="radioTabs[0]"
                :label="'Липидный профиль'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="4"
                v-model="radioTabs[0]"
                :label="'Коагулограмма'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="5"
                v-model="radioTabs[0]"
                :label="'Электролиты сыворотки крови'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="6"
                v-model="radioTabs[0]"
                :label="'Электрокардиография (ЭКГ)'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="7"
                v-model="radioTabs[0]"
                :label="'Суточное мониторирование ЭКГ от 16 апреля 2024'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="8"
                v-model="radioTabs[0]"
                :label="'Эхокардиография (ЭХОКГ)'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="9"
                v-model="radioTabs[0]"
                :label="'Суточное мониторирование артериального давления (СМАД)'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="10"
                v-model="radioTabs[0]"
                :label="'Тредмил-тест'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="11"
                v-model="radioTabs[0]"
                :label="'Рентгенография органов грудной клетки'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="12"
                v-model="radioTabs[0]"
                :label="'Суточное мониторирование ЭКГ от 2 июля 2024'"
              />
            </div>
            <div class="d-xl-none">
              <Select
                :placeholder="'Выберите результат'"
                :items="list"
                :multiple="false"
                v-model="radioTabs"
                :searchable="false"
              >
                <template #list-header>
                  <div class="select-form__select-header">
                    Выберите результат
                  </div>
                </template>
              </Select>
            </div>
          </div>
          <div
            class="icc__block-white-plate"
            :style="`opacity: ${radioTabs[0] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 0"
            >
              <div class="title-1 mb-4">Клинический анализ крови</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table1.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table1.jpg')} 3x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 1"
            >
              <div class="title-1 mb-4">Общий анализ мочи</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table2.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table2.jpg')} 3x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 2"
            >
              <div class="title-1 mb-4">Биохимический анализ крови</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table3.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table3.jpg')} 3x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 3"
            >
              <div class="title-1 mb-4">Липидный профиль</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table4.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table4.jpg')} 3x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 4"
            >
              <div class="title-1 mb-4">Коагулограмма</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table5.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table5.jpg')} 3x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 5"
            >
              <div class="title-1 mb-4">Электролиты сыворотки крови</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table6.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table6.jpg')} 3x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 6"
            >
              <div class="title-1 mb-4">Электрокардиография (ЭКГ)</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step3__table7.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__table7.jpg')} 3x`"
                />
              </div>
              <div class="text-2 mb-4">
                <b>На ЭКГ при поступлении:</b> P = 0.00, PQ = 0.00, QRS = 0.08,
                QRST = 0.28, ЧСС = 105, QRST корр. = 370 мс. Фибрилляция
                предсердий, отклонение ЭОС влево, неспецифические нарушения
                реполяризации на фоне аритмии.
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 7"
            >
              <div class="title-1 mb-4">Суточное мониторирование ЭКГ</div>
              <div class="text-2 mb-4">
                <div class="mb-2">
                  Желудочковая экстрасистолия 3 градации по Ryan.
                </div>
                <div class="mb-2">
                  Регистрируются желудочковые аритмии, количество аритмий –
                  пограничное.
                </div>
                <div class="mb-2">
                  Cинусовый ритм в течение времени наблюдения, с ЧСС от 54 до
                  138 (средняя 73) уд/мин.
                </div>
                <div class="mb-2">Наблюдались следующие аритмии:</div>
                <div class="mb-2">
                  Одиночные желудочковые экстрасистолы 1 типа с предэктопическим
                  интервалом от 370 до 436 (в среднем 401) мсек.
                </div>
                <div class="mb-2">
                  ВСЕГО: 39. (2 в час). Днем: 18. (1 в час). Ночью: 21. (3 в
                  час).
                </div>
                <div class="mb-2">
                  Одиночные желудочковые экстрасистолы 2 типа с предэктопическим
                  интервалом от 693 до 984 (в среднем 872) мсек.
                </div>
                <div class="mb-2">
                  ВСЕГО: 7. (менее 1 в час). Днем: 6. (менее 1 в час). Ночью: 1.
                  (менее 1 в час).
                </div>
                <div>
                  Значимых пауз более 2 сек. Выявлено не было. Средний
                  корригированный QT интервал (по формуле Bazett) за сутки 459
                  мс. Ишемические изменения ЭКГ не обнаружены.
                </div>
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 16 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 8"
            >
              <div class="title-1 mb-4">Эхокардиография (ЭХОКГ)</div>
              <table class="text-1 d-none d-md-table">
                <tr>
                  <td class="pb-2">ФВ – 60%</td>
                  <td class="pb-2">иММЛЖ – 89 г/м&#178;</td>
                  <td class="pb-2">ИЛПоб – 33 мл/м&#178;.</td>
                </tr>
                <tr>
                  <td class="pb-2">МЖП – 9 мм</td>
                  <td class="pb-2">ЛПоб – 63.0 мл</td>
                  <td class="pb-2">ЛЖ не увеличен.</td>
                </tr>
                <tr>
                  <td class="pb-2">ЗС – 9 мм</td>
                </tr>
              </table>
              <table class="text-1 d-md-none">
                <tr>
                  <td class="pb-2">ФВ – 60%</td>
                  <td class="pb-2">ЛПоб – 63.0 мл</td>
                </tr>
                <tr>
                  <td class="pb-2">МЖП – 9 мм</td>
                  <td class="pb-2">ИЛПоб – 33 мл/м&#178;.</td>   
                </tr>
                <tr>
                  <td class="pb-2">ЗС – 9 мм</td>
                  <td class="pb-2">ЛЖ не увеличен.</td> 
                </tr>
                <tr>
                  <td class="pb-2">иММЛЖ – 89 г/м&#178;</td> 
                </tr>
              </table>
              <div class="text-2 mb-4">
                Миокард не утолщен. Локальных нарушений сократимости не найдено.
                Глобальная сократимость сохранена. Левое предсердие
                незначительно увеличено. Аорта не расширена. Клапан
                трехполулунный, неизменен. Нарушений кровотока не выявлено.
                Створки митрального клапана не изменены. Регургитация 1 ст.
                Правые отделы сердца не расширены. Глобальная сократимость ПЖ не
                снижена. Пульмональный и трехстворчатый клапаны обычные.
                Легочный ствол не расширен. Нижняя полая вена расширена,
                спадается более 50%. Расчетное систолическое давление в ЛА не
                повышено. Межпредсердная перегородка тонкая, патологического
                сброса через нее не найдено. Избытка жидкости в полости
                перикарда не найдено.
              </div>
              <div class="d-flex mb-4">
                <img
                  data-coolbox="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/echokg/1.mp4"
                  class="mr-4"
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__gif1.jpg')} 2x`"
                />
                <img
                  data-coolbox="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/echokg/2.mp4"
                  class="mr-4"
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__gif1.jpg')} 2x`"
                />
                <img
                  data-coolbox="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/echokg/3.mp4"
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step3__gif1.jpg')} 2x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 9"
            >
              <div class="title-1 mb-4">
                Суточное мониторирование артериального давления (СМАД)
              </div>
              <div class="text-2 mb-4">
                Заключение: при суточном измерении АД на фоне терапии
                максимальное повышение АД до 135/79 мм рт. ст. на фоне
                физической нагрузки, в покое среднее АД 125/70 мм рт.ст.,
                подъемов АД более 140/90 не зарегистрировано.
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 10"
            >
              <div class="title-1 mb-4">Нагрузочное тестирование ЭКГ</div>
              <div class="text-2 mb-2"><b>Тип пробы:</b> тредмил.</div>
              <div class="text-2 mb-2"><b>Протокол:</b> M-BRUCE.</div>
              <div class="text-2 mb-2">
                <b>Ишемические изменения ЭКГ:</b> горизонтальная депрессия
                сегмента ST: V4-6 до-1,5 мм.
              </div>
              <div class="text-2 mb-2"><b>Метаболических единиц:</b> 7,1.</div>
              <div class="text-2 mb-2"><b>Двойное произведение:</b> 280.</div>
              <div class="text-2 mb-4">
                <b>Заключение:</b> Проба положительная по клиническим
                (дискомфорт за грудиной сжимающий) и ЭКГ-данным.
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 11"
            >
              <div class="title-1 mb-4">
                Рентгенография органов грудной клетки
              </div>
              <div class="text-2 mb-4">
                <b>Рентгенография органов грудной клетки:</b> на обзорной
                рентгенограмме органов грудной полости свежих очаговых и
                инфильтративных изменений не выявлено. Синусы свободны.
              </div>
              <div class="d-flex mb-4">
                <img
                  style="width: calc(60% - 8px); cursor: pointer"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/rentgen1.png')
                  "
                  class="mr-4"
                  :srcset="`${require('@/assets/img/interactive-clinical-case/rentgen1.png')} 2x`"
                />
                <img
                  style="width: calc(40% - 8px); cursor: pointer"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/rentgen2.png')
                  "
                  :srcset="`${require('@/assets/img/interactive-clinical-case/rentgen2.png')} 2x`"
                />
              </div>
              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 15 апреля 2024
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 12"
            >
              <div class="title-1 mb-4">Суточное мониторирование ЭКГ</div>
              <div class="text-2 mb-2">
                Основной ритм – синусовый ритм в течение времени наблюдения, с
                ЧСС от 49 до 133 (средняя 69) уд/мин.
              </div>
              <div class="text-2 mb-2">
                Фибрилляция предсердий суммарно за время наблюдения
                продолжительностью 2 часа, 13 минут с ЧСС от 72 до 147 (средняя
                105) уд/мин.
              </div>
              <div class="text-2 mb-2">
                Зарегистрирована единичная мономорфная желудочковая эктопическая
                активность в виде 53 одиночных экстрасистол. Зарегистрирована
                единичная наджелудочковая эктопическая активность в виде
                одиночных и парных экстрасистол в среднем количестве 15 в час.
              </div>
              <div class="text-2 mb-4">
                Значимые изменения ST-T не зарегистрированы. Средний
                корригированный QT интервал (по формуле Bazett) за сутки 424 мс.
                Значимых пауз более 2 сек. Выявлено не было.
              </div>

              <div class="icc__block-table-approval mt-auto mb-sm-4">
                Дата выполнения: 2 июля 2024
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="4"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
import Select from "@/components/interactiveClinicalCase/Select.vue";
export default {
  name: "ICCStep3",
  components: {
    Steps,
    RadioButton,
    Select,
  },
  data: () => ({
    radioTabs: [],
  }),
  computed: {
    list() {
      return [
        { label: "Клинический анализ крови", value: 0 },
        { label: "Общий анализ мочи", value: 1 },
        { label: "Биохимический анализ крови", value: 2 },
        { label: "Липидный профиль", value: 3 },
        { label: "Коагулограмма", value: 4 },
        { label: "Электролиты сыворотки крови", value: 5 },
        { label: "Электрокардиография (ЭКГ)", value: 6 },
        { label: "Суточное мониторирование ЭКГ от 16 апреля 2024", value: 7 },
        { label: "Эхокардиография (ЭХОКГ)", value: 8 },
        {
          label: "Суточное мониторирование артериального давления (СМАД)",
          value: 9,
        },
        { label: "Тредмил-тест ", value: 10 },
        { label: "Рентгенография органов грудной клетки", value: 11 },
        { label: "Суточное мониторирование ЭКГ от 2 июля 2024", value: 12 },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";

.icc {
  & .select-form {
    &__select-header {
      margin-bottom: 16px;
      padding: 10px 16px;
      border-radius: 8px;
      background: #f8f8f8;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #3c4242;
    }
  }
}
</style>