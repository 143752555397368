<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="
                  require('@/assets/img/interactive-clinical-case/step8_icon1.svg')
                "
              />
              Дискуссия с экспертом
            </div>
            <div class="text-1 mb-6">
              В этом видео мы рассмотрим, какие обязательные инструментальные
              исследования необходимо провести для данной пациентки в
              соответствии с клиническими рекомендациями.
            </div>
            <div class="icc__block-video mx-auto mb-6">
              <video
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/4.mp4"
                :poster="
                  require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                "
                controls
              />
            </div>
            <div v-if="activeTab == 0">
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                Фибрилляция предсердий
              </div>
              <div class="text-1 mb-8">
                <div class="mb-2">
                  С целью подтверждения наличия ФП всем пациентам с подозрением
                  на ФП рекомендовано <b>выполнение ЭКГ</b><sup>1</sup>.
                </div>
                <div class="mb-2">
                  С целью оценки сердечной структуры и функции всем пациентам с
                  ФП рекомендовано
                  <b>проведение трансторакальной эхокардиографии</b
                  ><sup>1</sup>.
                </div>
                <div class="mb-2">
                  Перед кардиоверсией при пароксизме фибрилляции предсердий
                  неизвестной давности или пароксизме более 48 часов, в качестве
                  альтернативы длительной антикоагуляции, рекомендовано
                  исключение наличия тромба в левом предсердии и его ушке
                  с помощью <b>чреспищеводной эхокардиографии</b> на фоне
                  предварительно созданного терапевтического уровня
                  антикоагуляции<sup>1</sup>.
                </div>
              </div>
            </div>
            <div v-if="activeTab == 1">
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                Стабильная стенокардия
              </div>
              <div class="text-1 mb-8">
                <div class="mb-2">
                  Регистрация с расшифровкой, описанием и интерпретацией
                  12-канальной электрокардиограммы (ЭКГ) рекомендована всем
                  пациентам с подозрением на ИБС для выявления признаков ишемии,
                  а также возможного наличия зубца Q, сопутствующих нарушений
                  ритма и проводимости сердца:
                </div>
                <ul class="mb-8">
                  <li>в покое;</li>
                  <li>
                    во время или сразу после приступа боли в грудной
                    клетке<sup>3</sup>.
                  </li>
                </ul>
                <div class="mb-2">
                  <b>Трансторакальная эхокардиография (ЭхоКГ)</b> в состоянии
                  покоя с использованием допплеровских режимов рекомендована
                  всем пациентам с подозрением на ИБС для:
                </div>
                <div class="mb-2">
                  <span style="color: #d0006f">1.</span> исключения других
                  причин боли в грудной клетке;
                </div>
                <div class="mb-2">
                  <span style="color: #d0006f">2.</span> выявления нарушений
                  локальной сократимости левого желудочка;
                </div>
                <div class="mb-2">
                  <span style="color: #d0006f">3.</span> измерения фракции
                  выброса (ФВ) ЛЖ;
                </div>
                <div class="mb-2">
                  <span style="color: #d0006f">4.</span> оценки диастолической
                  функции ЛЖ;
                </div>
                <div class="mb-6">
                  <span style="color: #d0006f">5.</span> выявления патологии
                  клапанного аппарата сердца<sup>3</sup>.
                </div>
                <blockquote class="px-6 py-4 mb-8">
                  <div class="text-1 mb-2">
                    Проведение
                    <b
                      >магнитно-резонансной томографии сердца и магистральных
                      сосудов</b
                    >
                    в состоянии покоя с целью получения информации о структуре и
                    функции сердца рекомендуется пациентам с подозрением на ИБС
                    <b>в случае неубедительных результатов ЭхоКГ</b> и при
                    отсутствии противопоказаний<sup>3</sup>.
                  </div>
                </blockquote>
                <div class="mb-2">
                  <b>Прицельная рентгенография органов грудной клетки</b>
                  рекомендуется пациентам с подозрением на ИБС и сердечную
                  недостаточность для определения наличия и выраженности
                  нарушений внутрилегочной гемодинамики (венозного застоя,
                  легочной артериальной гипертензии), а также свободной жидкости
                  в плевральных полостях<sup>3</sup>.
                </div>
                <div class="mb-2">
                  <b>Холтеровское мониторирование сердечного ритма</b>
                  рекомендуется пациентам с ИБС или подозрением на ИБС и
                  сопутствующими нарушениями ритма и/или проводимости, при
                  необходимости, подбора терапии<sup>3</sup>.
                </div>
                <div class="mb-8">
                  Всем пациентам с подозрением на ИБС без ранее
                  верифицированного атеросклероза любой локализации
                  рекомендуется
                  <b
                    >дуплексное сканирование экстракраниальных отделов сонных
                    артерий</b
                  >
                  для выявления атеросклеротических бляшек<sup>3</sup>.
                </div>
                <div class="mb-2">
                  В качестве первого неинвазивного визуализирующего теста для
                  диагностики ИБС рекомендуется один из неинвазивных
                  визуализирующих стресс-методов выявления ишемии
                  миокарда<sup>3</sup>:
                </div>
                <ul class="mb-8">
                  <li>
                    эхокардиография с физической нагрузкой, или с чреспищеводной
                    стимуляцией, или с фармакологической нагрузкой;
                  </li>
                  <li>сцинтиграфия миокарда с функциональными пробами;</li>
                  <li>позитронно-эмиссионная томография миокарда;</li>
                  <li>
                    однофотонная эмиссионная компьютерная томография миокарда.
                  </li>
                </ul>
                <div class="mb-4">
                  Пациентам с ИБС или подозрением на ИБС
                  <b>рекомендуется рассмотреть нагрузочную ЭКГ</b> (ЭКГ с
                  физической нагрузкой на тредмиле или велоэргометре),
                  выполненную на фоне отмены антиишемической терапии, как
                  альтернативный тест для верификации ишемии в случае, когда
                  визуализирующие методы (стресс-методы визуализации или
                  МСКТ-ангиография) технически не могут быть
                  проведены<sup>3</sup>.
                </div>
                <blockquote class="px-6 py-4 mb-8">
                  <div class="text-1 mb-2">
                    Пациентам с подозрением на ИБС для выявления факторов,
                    модифицирующих ПТВ ИБС, может быть рекомендована оценка
                    коронарного кальция с помощью компьютерной томографии сердца
                    (при наличии возможности) с расчетом индекса
                    Агатсона<sup>3</sup>.
                  </div>
                  <div class="text-1">
                    Выполнение МСКТ КА рекомендуется пациентам с СН с низкой или
                    промежуточной вероятностью ИБС и неинформативностью
                    неинвазивных методов выявления ИБС для выявления
                    стенозирующего поражения коронарных артерий<sup>3</sup>.
                  </div>
                </blockquote>
              </div>
            </div>
            <div v-if="activeTab == 2">
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                Гипертоническая болезнь
              </div>
              <div class="text-1 mb-4">
                <div class="mb-2">
                  Всем пациентам с артериальной гипертензией (АГ) для выявления
                  гипертрофии левого желудочка (ГЛЖ) и определения
                  сердечно-сосудистого риска рекомендуется
                  <b>проведение 12-канальной ЭКГ</b><sup>4</sup>.
                </div>
                <div class="mb-2">
                  Пациентам с АГ при наличии изменений на ЭКГ или
                  симптомов/признаков дисфункции левого желудочка рекомендуется
                  <b>проведение ЭхоКГ</b> для выявления степени ГЛЖ<sup>4</sup>.
                </div>
                <div class="mb-2">
                  Пациентам с АГ в сочетании с цереброваскулярной болезнью (ЦВБ)
                  или признаками атеросклеротического поражения сосудов других
                  локализаций, при указании в анамнезе на преходящую слабость в
                  конечностях с одной стороны или онемение половины тела, а
                  также мужчинам старше 40 лет, женщинам старше 50 лет и
                  пациентам с высоким общим сердечно-сосудистым риском
                  рекомендуется
                  <b
                    >дуплексное сканирование брахиоцефальных артерий для
                    выявления атеросклеротических бляшек/стенозов внутренних
                    сонных артерий</b
                  ><sup>4</sup>.
                </div>
                <div class="mb-4">
                  Всем пациентам с нарушением функции почек, альбуминурией и при
                  подозрении на вторичную АГ рекомендуется проведение
                  <b>УЗИ почек и дуплексного сканирования артерий почек</b> с
                  целью оценки размеров, структуры, а также наличия врожденных
                  аномалий почек или стеноза почечных артерий<sup>4</sup>.
                </div>
              </div>
            </div>
            <div v-if="activeTab == 3">
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                Хроническая сердечная недостаточность
              </div>
              <div class="text-1 mb-6">
                <div class="mb-2">
                  Всем пациентам c ХСН рекомендуется выполнение 12-канальной
                  ЭКГ. Диагноз ХСН маловероятен при наличии абсолютно нормальной
                  ЭКГ<sup>5</sup>.
                </div>
                <div class="mb-2">
                  Всем пациентам с подозрением на сердечную недостаточность
                  рекомендуется эхокардиография для оценки структуры и функции
                  сердца с целью подтверждения диагноза и установления фенотипа
                  сердечной недостаточности<sup>5</sup>.
                </div>
                <div class="mb-2">
                  Выполнение прицельной рентгенографии органов грудной клетки
                  пациентам с СН рекомендуется для выявления альтернативных
                  заболеваний легких, выявления нарушений легочной гемодинамики;
                  выявления кардиомегалии<sup>5</sup>.
                </div>
                <div>
                  Пациентам с ХСН и ФП, которым планируется восстановление
                  синусового ритма, рекомендуется проведение эхокардиографии
                  чреспищеводной для исключения тромбоза ушка левого
                  предсердия<sup>5</sup>.
                </div>
              </div>
              <blockquote class="px-6 py-4 mb-4">
                <div class="text-1">
                  Выполнение магнитно-резонансной томографии (МРТ) сердца и
                  магистральных сосудов рекомендуется пациентам, у которых
                  неинформативна ЭхоКГ, для оценки анатомии и функции сердца,
                  систолической и диастолической дисфункции, фракции выброса
                  ЛЖ<sup>5</sup>.
                </div>
              </blockquote>
            </div>
            <div v-if="activeTab == 4">
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                Ожирение
              </div>
              <div class="text-1 mb-6">
                <div class="mb-2">
                  Для обследования пациентов с АГ, ИБС,
                  ХСН рекомендуется регистрация ЭКГ и/или
                  эхокардиографии<sup>6</sup>.
                </div>
                <div>
                  Всем пациентам рекомендуется проведение УЗИ органов брюшной
                  полости с целью диагностики желчнокаменной болезни и
                  НАЖБП<sup>6</sup>.
                </div>
              </div>
              <blockquote class="px-6 py-4 mb-4">
                <div class="text-1">
                  При наличии клинических проявлений СОАС
                  <b>рекомендуется</b> проведение ночной пульсоксиметрии, по
                  показаниям – полисомнографии<sup>6</sup>.
                </div>
              </blockquote>
            </div>
            <div v-if="activeTab == 5">
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                О коронарографии
              </div>
              <div class="text-1 mb-2">
                <b>Рекомендуется пациентам</b><sup>3,5</sup>:
              </div>
              <div class="text-1">
                <ul>
                  <li>
                    с СН и стенокардией напряжения, не поддающейся лекарственной
                    терапии;
                  </li>
                  <li>
                    с наличием симптомных желудочковых аритмий или после
                    остановки сердца;
                  </li>
                  <li>
                    с СН и наличием от промежуточной до высокой предтестовой
                    вероятности ИБС и/или наличием ишемии миокарда по данным
                    стресс-тестов;
                  </li>
                  <li>
                    с тяжелой стабильной стенокардией (ФК III–IV) или с
                    клиническими признаками высокого риска ССО;
                  </li>
                  <li>
                    с длительным анамнезом ИБС с высоким риском ССО при
                    появлении признаков ишемии по данным неинвазивного
                    стресс-тестирования и/или выраженных симптомах ишемии
                    (стенокардия напряжения III–IV ФК), а также при необъяснимом
                    снижении локальной сократимости миокарда ЛЖ;
                  </li>
                  <li>
                    перед оперативным лечением клапанной патологии сердца при
                    наличии любого из нижеперечисленных признаков: анамнеза
                    сердечно-сосудистых заболеваний (указание на наличие
                    стенокардии), подозрения на ишемию миокарда, систолической
                    дисфункции левого желудочка, у мужчин старше 40 лет и женщин
                    в постменопаузальном периоде, а также при наличии одного или
                    нескольких факторов риска ССО;
                  </li>
                  <li>
                    при подозрении на вазоспастическую
                    стенокардию<sup>3,5</sup>.
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="activeTab == 6">
              <div
                class="title-2 mb-6"
                style="color: #830051; text-align: center"
              >
                Итоговый перечень необходимых инструментальных исследований для
                данной пациентки<sup>1-6</sup>
              </div>
              <div class="text-1 mb-8">
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    12-канальная ЭКГ (ФП, ИБС, ГБ, ХСН);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    Трансторакальная эхокардиография (ФП, ИБС, ГБ, ХСН);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    Чреспищеводная эхокардиография (ФП);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    Прицельная рентгенография органов грудной клетки (ИБС, ХСН);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">Суточное мониторирование ЭКГ (ИБС);</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    Неинвазивный визуализирующий стресс-тест/нагрузочная ЭКГ
                    (ИБС);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    УЗДГ брахиоцефальных артерий (ИБС, ГБ);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    УЗИ почек и дуплексное сканирование артерий почек (ГБ);
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    УЗИ органов брюшной полости (ожирение);
                  </div>
                </div>
                <div class="d-flex">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/green_check.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">
                    Оценка коронарного кальция с помощью компьютерной томографии
                    сердца (по возможности);
                  </div>
                </div>
              </div>
              <div class="text-1 mb-2">
                <b>НЕ требуется выполнение:</b>
              </div>
              <div class="text-1 mb-4">
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/pink_cross.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">Коронарографии;</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/pink_cross.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">МРТ сердца;</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/pink_cross.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">МСКТ коронарных артерий;</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/pink_cross.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">КТ-пульмонография;</div>
                </div>
                <div class="d-flex mb-2">
                  <div class="mr-3" style="flex-shrink: 0">
                    <img
                      width="24"
                      :src="
                        require('@/assets/img/interactive-clinical-case/pink_cross.svg')
                      "
                    />
                  </div>
                  <div class="my-auto">Суточное мониторирование АД.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="icc__tabs">
        <div class="icc__tabs-tip d-xl-none">
          <img
            class="mr-2"
            :src="require('@/assets/img/interactive-clinical-case/tap.svg')"
          />
          Выберите следующее действие на вкладках ниже
        </div>
        <div class="icc__tabs-row">
          <div
            class="icc__tab"
            @click="setActiveTab(0)"
            :class="{ icc__tab_active: activeTab == 0 }"
          >
            Фибрилляция предсердий
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(1)"
            :class="{ icc__tab_active: activeTab == 1 }"
          >
            Стабильная стенокардия
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(2)"
            :class="{ icc__tab_active: activeTab == 2 }"
          >
            Гипертоническая болезнь
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(3)"
            :class="{ icc__tab_active: activeTab == 3 }"
          >
            ХСН
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(4)"
            :class="{ icc__tab_active: activeTab == 4 }"
          >
            Ожирение
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(5)"
            :class="{ icc__tab_active: activeTab == 5 }"
          >
            О коронарографии
          </div>
          <div
            class="icc__tab"
            @click="setActiveTab(6)"
            :class="{ icc__tab_active: activeTab == 6 }"
          >
            Итоговый перечень...
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="prevStep"
        @nextStep="nextStep"
        :stepsCount="18"
        :activeStep="9"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";

export default {
  name: "ICCStep11",
  components: {
    Steps,
  },
  props: {
    step: Object,
  },
  data: () => ({
    activeTab: 0,
  }),
  computed: {
    lastViewedTab() {
      return this.step.tabs.filter((tab) => tab.is_viewed).pop();
    },
  },
  methods: {
    prevStep() {
      if (this.activeTab > 0) {
        this.setActiveTab(this.activeTab - 1);
      } else {
        this.$emit("prevStep");
      }
    },
    nextStep() {
      if (this.activeTab < 6) {
        this.setActiveTab(this.activeTab + 1);
      } else {
        this.$emit("nextStep");
      }
    },
    setActiveTab(ind, noRequest) {
      this.activeTab = ind;
      if (!this.step.tabs[this.activeTab].is_viewed && !noRequest) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/user-progress`,
          params: params,
          method: "POST",
          data: {
            step: this.step.slug,
            tab: this.step.tabs[this.activeTab].slug,
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
      this.$nextTick(() => {
        this.$el
          .querySelector(".icc__tabs-row")
          .scrollTo(
            this.$el.querySelector(".icc__tab_active").offsetLeft - 76,
            0
          );
      });
    },
  },
  mounted() {
    if (this.lastViewedTab) {
      this.setActiveTab(this.lastViewedTab.internal_number, true);
    } else {
      this.setActiveTab(0, true);
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>