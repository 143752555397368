<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4 mb-6">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title icc__block-title_with-icon">
              <img
                :src="
                  require('@/assets/img/interactive-clinical-case/step8_icon1.svg')
                "
              />
              Дискуссия с экспертом
            </div>
            <div class="text-1 mb-4">
              <b>ПРОФИЛАКТИКА РЕЦИДИВОВ ФИБРИЛЛЯЦИИ ПРЕДСЕРДИЙ</b>
            </div>
            <div class="text-1 mb-2">
              <b
                >Длительная медикаментозная антиаритмическая терапия
                рекомендуется для контроля ритма у пациентов:</b
              >
            </div>
            <div class="text-1 mb-2">
              <ul>
                <li>с симптоматичной рецидивирующей ФП;</li>
                <li>персистирующей формой ФП после кардиоверсии;</li>
                <li>
                  у пациентов после абляции ФП в течение первых 3 месяцев или
                  неопределенно долго (как составляющая часть гибридного
                  подхода).
                </li>
              </ul>
            </div>
            <div class="text-1 mb-6">
              При отсутствии симптомов (в том числе на фоне адекватного контроля
              ЧСС) больным обычно не следует назначать антиаритмические
              средства<sup>1,2</sup>.
            </div>
            <div class="full-screen mb-4 mx-auto">
              <div
                class="full-screen__trigger"
                :data-coolbox="
                  require('@/assets/img/interactive-clinical-case/step17__table1.jpg')
                "
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="40" height="40" rx="8" fill="#830051"></rect>
                  <path
                    d="M23 11H29V17"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M17 29H11V23"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M29 11L22 18"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                  <path
                    d="M11 29L18 22"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>
                </svg>
              </div>

              <img
                :srcset="`${require('@/assets/img/interactive-clinical-case/step17__table1.jpg')} 3x`"
              />
            </div>
            <div class="text-3 mb-8">
              <i>
                Примечание: *Структурное поражение сердца (постинфарктный
                кардиосклероз, сниженная систолическая функция левого желудочка,
                фракция выброса левого желудочка 40% и менее — с любыми
                проявлениями сердечной недостаточности, а также при гипертрофии
                миокарда левого желудочка, превышающей 14 мм по данным ЭхоКГ);
                <br />
                **У пациентов без структурных заболеваний сердца профилактику
                рецидивов ФП рекомендовано начинать с бета-адреноблокаторов,
                особенно когда аритмия четко связана с психическим или
                физическим напряжением.
              </i>
            </div>
            <div class="text-1 mb-6">
              В видео обсудим актуальные стратегии лечения пациентов с
              фибрилляцией предсердий
            </div>
            <div class="icc__block-video mx-auto mb-6">
              <video
                src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/6.mp4"
                :poster="
                  require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                "
                controls
              />
            </div>
            <div>
              <div
                class="title-2 mb-4"
                style="color: #830051; text-align: center"
              >
                Стратегия «контроля частоты»
              </div>
              <div class="text-1 mb-2">
                Наличие либо отсутствие симптомов влияет на целевые показатели
                частоты желудочковых сокращений (ЧЖС) при подборе терапии для
                контроля частоты<sup>1</sup>.
              </div>
              <div class="text-1 mb-6">
                Согласно рекомендациям стартовая доза метопролола сукцината –
                100&nbsp;мг 1 раз в сутки с постепенным увеличением под
                контролем ЧЖС<sup>1</sup>.
              </div>
              <div class="title-3 mb-4" style="text-align: center">
                Тактика контроля частоты у пациента с
                <b style="color: #830051">бессимптомной</b
                ><br class="d-md-block d-none" />
                фибрилляцией/трепетанием предсердий
              </div>
              <div class="full-screen mb-4 mx-auto">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step17__table2.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step17__table2.jpg')} 3x`"
                />
              </div>
              <div class="text-3 mb-8">
                <i>
                  Примечание: ЧЖС – частоты желудочковых сокращений; АВ –
                  атриовентрикулярный; ЭКС – электрокардиостимулятор.
                </i>
              </div>
              <div class="title-3 mb-4" style="text-align: center">
                Тактика контроля частоты у пациента с
                <b style="color: #830051">симптомной</b
                ><br class="d-md-block d-none" />
                фибрилляцией/трепетанием предсердий
              </div>
              <div class="full-screen mb-4 mx-auto">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step17__table3.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step17__table3.jpg')} 3x`"
                />
              </div>
              <div class="text-3 mb-8">
                <i>
                  Примечание: ЧЖС – частоты желудочковых сокращений; АВ –
                  атриовентрикулярный; ЭКС – электрокардиостимулятор.</i
                >
              </div>
              <div class="text-1 mb-8">
                Метопролола сукцинат, один из немногих селективных
                бета-адреноблокаторов, входящих в стандарт оказания медицинской
                помощи взрослым при фибрилляции и трепетании
                предсердий<sup>1,2</sup>.
              </div>
              <div class="full-screen mb-4 mx-auto">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step17__table4.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step17__table4.jpg')} 3x`"
                />
              </div>
              <div class="text-3 mb-4">
                <i>
                  Примечание: *среди оригинальных бета-блокаторов, принимаемых 1
                  раз в сутки.
                  <br />
                  АГ – артериальная гипертония; ХСН – хроническая сердечная
                  недостаточность; ИМ – инфаркт миокарда.
                  <br />
                  Источник: Государственный реестр лекарственных средств
                  [Электронный ресурс]. 05-11-2024
                  URL:http://grls.rosminzdrav.ru
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="13"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";

export default {
  name: "ICCStep17",
  components: {
    Steps,
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>