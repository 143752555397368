<template>
  <div class="icc__fixed-container" v-if="fullData && fullData.length">
    <div class="icc__back-block" @click="exitPopup = true">
      <div class="button button_gray" style="color: #3c4242">
        <svg
          class="mr-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.25 4.5L3.75 12L11.25 19.5"
            stroke="#8B8E8E"
            stroke-width="1.5"
            stroke-linecap="square"
          />
          <path
            d="M5.25 12L20.25 12"
            stroke="#8B8E8E"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>

        Вернуться на главную
      </div>
    </div>
    <PopUpDefault @close="exitPopup = false" v-if="exitPopup">
      <div class="title-1 mb-4" style="text-align: center; font-weight: 600">
        Вы уверены, что хотите завершить квиз и вернуться на главную страницу
        клинического случая?
      </div>
      <div class="d-md-flex">
        <div
          class="button button_pink mr-2 ml-auto mr-sm-0 mb-sm-4"
          @click="exitPopup = false"
        >
          Нет, остаться
        </div>
        <div
          class="button button_empty-pink ml-2 mr-auto ml-sm-0"
          @click="close"
        >
          Да, завершить
        </div>
      </div>
    </PopUpDefault>
    <Step1
      :step="fullData[1]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 0"
      @setNextTab="setNextTab"
    />
    <Step2
      :step="fullData[2]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 1"
      @clearActiveTab="clearActiveTab"
      :activeTabParent="activeTabParent"
    />
    <Step3
      :step="fullData[3]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 2"
    />
    <Step4
      :step="fullData[4]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 3"
    />
    <Step5
      :step="fullData[5]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 4"
    />
    <Step6
      :step="fullData[6]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 5"
    />
    <Step7
      :step="fullData[7]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 6"
    />
    <Step8
      :step="fullData[8]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 7"
    />
    <Step9
      :step="fullData[9]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 8"
    />
    <Step10
      :step="fullData[10]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 9"
    />
    <Step11
      :step="fullData[11]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 10"
    />
    <Step12
      :step="fullData[12]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 11"
    />
    <Step13
      :step="fullData[13]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 12"
    />
    <Step14
      :step="fullData[14]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 13"
    />
    <Step15
      :step="fullData[15]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 14"
    />
    <Step16
      :step="fullData[16]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 15"
    />
    <Step17
      :step="fullData[17]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 16"
    />
    <Step18
      :step="fullData[18]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 17"
    />
    <Step19
      :step="fullData[19]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 18"
    />
    <Step20
      :step="fullData[20]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 19"
    />
    <Step21
      :step="fullData[21]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 20"
    />
    <Step22
      :step="fullData[22]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 21"
    />
    <Step23
      :step="fullData[23]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 22"
      :question1="fullData[16].test.questions[0]"
      :question2="fullData[20].test.questions[0]"
      :question3="fullData[18].test.questions[0]"
    />
    <Step24
      :step="fullData[24]"
      @prevStep="prevStep"
      @nextStep="nextStep"
      v-show="activeStep == 23"
      @refreshClinicalCase="refreshClinicalCase"
    />
  </div>
</template>

<script>
import PopUpDefault from "@/components/pageComponents/PopUpDefault.vue";
import Step1 from "@/components/interactiveClinicalCase/Step1.vue";
import Step2 from "@/components/interactiveClinicalCase/Step2.vue";
import Step3 from "@/components/interactiveClinicalCase/Step3.vue";
import Step4 from "@/components/interactiveClinicalCase/Step4.vue";
import Step5 from "@/components/interactiveClinicalCase/Step5.vue";
import Step6 from "@/components/interactiveClinicalCase/Step6.vue";
import Step7 from "@/components/interactiveClinicalCase/Step7.vue";
import Step8 from "@/components/interactiveClinicalCase/Step8.vue";
import Step9 from "@/components/interactiveClinicalCase/Step9.vue";
import Step10 from "@/components/interactiveClinicalCase/Step10.vue";
import Step11 from "@/components/interactiveClinicalCase/Step11.vue";
import Step12 from "@/components/interactiveClinicalCase/Step12.vue";
import Step13 from "@/components/interactiveClinicalCase/Step13.vue";
import Step14 from "@/components/interactiveClinicalCase/Step14.vue";
import Step15 from "@/components/interactiveClinicalCase/Step15.vue";
import Step16 from "@/components/interactiveClinicalCase/Step16.vue";
import Step17 from "@/components/interactiveClinicalCase/Step17.vue";
import Step18 from "@/components/interactiveClinicalCase/Step18.vue";
import Step19 from "@/components/interactiveClinicalCase/Step19.vue";
import Step20 from "@/components/interactiveClinicalCase/Step20.vue";
import Step21 from "@/components/interactiveClinicalCase/Step21.vue";
import Step22 from "@/components/interactiveClinicalCase/Step22.vue";
import Step23 from "@/components/interactiveClinicalCase/Step23.vue";
import Step24 from "@/components/interactiveClinicalCase/Step24.vue";
export default {
  name: "InteractiveClinicalCase",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Step6,
    Step7,
    Step8,
    Step9,
    Step10,
    Step11,
    Step12,
    Step13,
    Step14,
    Step15,
    Step15,
    Step16,
    Step17,
    Step18,
    Step19,
    Step20,
    Step21,
    Step22,
    Step23,
    Step24,
    PopUpDefault,
  },
  props: {
    fullData: Array,
  },
  data: () => ({
    activeStep: 0,
    exitPopup: false,
    activeTabParent: 0,
  }),
  computed: {
    lastViewedStep() {
      return this.fullData.filter((el) => el.is_viewed).pop();
    },
  },
  methods: {
    nextStep() {
      this.activeStep++;
    },
    prevStep() {
      if (this.activeStep > 0) {
        this.activeStep--;
      } else {
        this.close();
      }
    },
    close() {
      this.exitPopup = false
      this.$emit("close");
    },
    setNextTab(ind) {
      this.$set(this, "activeTabParent", ind);
      this.activeStep++;
    },
    clearActiveTab() {
      this.$set(this, "activeTabParent", 0);
    },
    refreshClinicalCase() {
      this.$emit("refreshClinicalCase");
    },
  },
  mounted() {
    if (this.lastViewedStep && this.lastViewedStep.internal_number) {
      this.$set(this, "activeStep", this.lastViewedStep.internal_number - 1);
    } else {
      this.$set(this, "activeStep", 0);
    }
  },
  watch: {
    activeStep() {
      if (!this.fullData[this.activeStep + 1].is_viewed) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/user-progress`,
          method: "POST",
          params: params,
          data: {
            step: this.fullData[this.activeStep + 1].slug,
          },
        })
          .then((res) => {
            if (
              this.fullData[this.activeStep + 1].test &&
              !this.fullData[this.activeStep + 1].test_result_id
            ) {
              this.$axios({
                url: `/api/clinical-case/test_result`,
                params: params,
                method: "POST",
                data: {
                  step: this.fullData[this.activeStep + 1].slug,
                  test: this.fullData[this.activeStep + 1].test.slug,
                },
              })
                .then((res) => {
                  this.fullData[this.activeStep + 1].test_result_id =
                    res.data.id;
                })
                .catch((error) => {});
            }
          })
          .catch((error) => {});
      }
      if (this.activeStep == 22) {
        this.$emit("getUserProgress");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>