<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Какие еще препараты нужно добавить к терапии с учетом
              сопутствующей патологии у данной пациентки?
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="radioTabs != null"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
                v-if="radioTabs != null"
              >
                <div
                  v-if="radioTabs == 0 || radioTabs == 5 || radioTabs == 7"
                  class="title-1 mb-4 d-flex"
                  style="color: #6bc399"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/green_check2.png')
                    "
                  />
                  Правильный ответ!
                </div>
                <div
                  v-if="radioTabs != 0 && radioTabs != 5 && radioTabs != 7"
                  class="title-1 mb-4 d-flex"
                  style="color: #cf1517"
                >
                  <img
                    class="mr-4"
                    :src="
                      require('@/assets/img/interactive-clinical-case/red_warning.png')
                    "
                  />
                  Неверный ответ!
                </div>
                <div>
                  <div class="mb-4">
                    <video
                      src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/9.mp4"
                      :poster="
                        require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                      "
                      controls
                    />
                  </div>
                  <div class="text-2 mb-4">
                    <div class="mb-4">
                      <b
                        >Выбор антигипертензивной терапии и лечения сердечной
                        недостаточности у коморбидного пациента</b
                      >
                    </div>

                    <div class="mb-8">
                      <ul>
                        <li>
                          Пациентам с диагностированной ФП и АГ рекомендовано
                          назначение ингибиторов АПФ или антагонистов
                          ангиотензина II в сочетании с ААТ с целью снижения
                          вероятности рецидивов ФП<sup>1</sup>.
                        </li>
                        <li>
                          Назначение ингибиторов АПФ и антагонистов ангиотензина
                          II (АРА) рекомендовано с целью профилактики ФП
                          пациентам с АГ, особенно при наличии гипертрофии
                          ЛЖ<sup>1</sup>.
                        </li>
                        <li>
                          Всем пациентам с АГ (кроме пациентов низкого риска с
                          АД&lt;150/90 мм рт. ст., пациентов ≥80 лет, пациентов
                          с синдромом старческой астении) в качестве стартовой
                          терапии рекомендована комбинация антигипертензивных
                          препаратов, предпочтительно фиксированная, для
                          улучшения приверженности к терапии. Предпочтительные
                          комбинации должны включать блокатор
                          ренин-ангиотензиновой системы (РААС) (ингибитор АПФ
                          или АРА) и дигидропиридиновый антагонист кальция (АК)
                          или диуретик<sup>4</sup>.
                        </li>
                        <li>
                          Всем пациентам с АГ и ХБП в качестве начальной терапии
                          рекомендуется комбинация блокатора РААС с АК или
                          диуретиком в связи с наилучшим влиянием данных
                          комбинаций на частоту достижения целевого АД и
                          снижение СС риска<sup>4</sup>.
                        </li>
                        <li>
                          Всем пациентам с АГ в сочетании с атеросклерозом
                          периферических артерий в качестве начальной терапии
                          рекомендуется назначать комбинацию блокатора РААС и АК
                          или диуретика<sup>4</sup>.
                        </li>
                        <li>
                          У пациентов со стабильной ИБС при сопутствующих
                          заболеваниях, имеющих существенное значение для
                          прогноза (постинфарктный кардиосклероз, АГ, СД, СН),
                          для профилактики ССО рекомендуется назначать иАПФ или
                          АРА<sup>3</sup>.
                        </li>
                        <li>
                          Пациентам с ХСН рекомендована терапия иАПФ/АРА/
                          валсартан+сакубитрил, бета-адреноблокаторами и
                          альдостерона антагонистами в том числе для снижения
                          частоты возникновения ФП<sup>5</sup>.
                        </li>
                        <li>
                          У пациентов, не достигших целевого АД при приеме моно-
                          или комбинированной АГТ, не включавшей диуретики,
                          рекомендуется назначение низких доз тиазидных или
                          тиазидоподобных диуретиков в составе комбинированной
                          терапии с АРА, ИАПФ и АК для усиления гипотензивного
                          эффекта и достижения целевого АД<sup>4</sup>.
                        </li>
                        <li>
                          Пациентам с СНсФВ и застойными явлениями рекомендованы
                          диуретики с целью устранения застойных явлений и
                          уменьшения выраженности симптомов и признаков
                          сердечной недостаточности<sup>5</sup>.
                        </li>
                        <li>
                          У пациентов с СНсФВ в эуволюмическом статусе, имеющих
                          выраженные функциональные ограничения и тяжёлую
                          диастолическую дисфункцию левого желудочка
                          рекомендуются диуретики для улучшения клинической
                          симптоматики СН<sup>5</sup>.
                        </li>
                        <li>
                          Тиазидные или петлевые диуретики рекомендуются для
                          усиления гипотензивной терапии при недостаточной
                          антигипертензивной эффективности применения комбинации
                          иАПФ (АРА вместо иАПФ, но не вместе!),
                          бета-адреноблокаторов и альдостерона антагонистов у
                          пациентов с ХСН и артериальной
                          гипертонией<sup>1</sup>.
                        </li>
                        <li>
                          Альдостерона антагонисты рекомендуются всем пациентам
                          с ХСН II-IV ФК и ФВ ЛЖ ≤ 40%, для снижения риска
                          госпитализации из-за СН и смерти<sup>5</sup>.
                        </li>
                        <li>
                          У пациентов с СН c умеренно сниженной или сохраненной
                          ФВ, включенных в исследование DELIVER, дапаглифлозин
                          снижал комбинированный риск сердечно-сосудистой смерти
                          или ухудшения событий сердечной недостаточности во
                          всем возрастном спектре, с постоянным профилем
                          безопасности<sup>23</sup>.
                        </li>
                        <li>
                          Ингибиторы натрий-глюкозного котранспортера 2 типа
                          обладают целым рядом прямых и опосредованных кардио- и
                          ренопротективных эффектов, обеспечивающих
                          эффективность их применения при ХСН, в т.ч. у больных
                          с ХБП<sup>24</sup>.
                        </li>
                        <li>
                          Только класс ингибиторов иНГЛТ-2 улучшает как прогноз,
                          так и качество жизни у пациентов с ХСНсФВ. АРНИ и АМКР
                          продемонстрировали эффективность только при
                          сопутствующей резистентной АГ. Кроме того, у ряда
                          пациентов может быть рассмотрено назначение комбинации
                          бета-блокаторов и селективного блокатора If-каналов
                          синусового узла при ЧСС > 70 уд/мин и синусовом ритме
                          и при сопутствующей ИБС. Остальные группы препаратов
                          значимо не влияют на качество жизни и прогноз
                          пациентов с ХСНсФВ и могут назначаться только при
                          наличии других показаний<sup>25</sup>.
                        </li>
                      </ul>
                    </div>
                    <div class="mb-4">
                      <b
                        >Место бета-адреноблокаторов в терапии коморбидного
                        пациента</b
                      >
                    </div>
                    <div class="mb-8">
                      <ul>
                        <li>
                          При стабильной стенокардии I–II ФК и ЧСС>60 уд/мин
                          рекомендуется назначить в качестве препарата 1-й линии
                          бета-адреноблокатор или недигидропиридиновые блокаторы
                          «медленных» кальциевых каналов (верапамил или
                          дилтиазем) для снижения ЧСС до значений 55–60
                          уд/мин<sup>3</sup>.
                        </li>
                        <li>
                          Бета-адреноблокаторы рекомендованы в качестве
                          антигипертензивной терапии при наличии особых
                          клинических ситуаций: например, стенокардии,
                          перенесенного инфаркта миокарда, сердечной
                          недостаточности<sup>4</sup>.
                        </li>
                        <li>
                          Пациентам с АГ и симптомами стенокардии рекомендуется
                          назначение ББ и/или АК<sup>4</sup>.
                        </li>
                        <li>
                          Пациентам с АГ и ФП при необходимости контроля ЧСС в
                          качестве компонента гипотензивной терапии
                          рекомендуется назначать бета-адреноблокаторы или
                          недигидропиридиновые антагонисты кальция<sup>4</sup>.
                        </li>
                        <li>
                          Бета-адреноблокаторы рекомендуются в качестве
                          препаратов 1-й линии антиангинальных препаратов у
                          пациентов с ХСН и стенокардией напряжения в связи с их
                          способностью не только уменьшать симптомы стенокардии,
                          но и снижать риск госпитализаций из-за СН и риск
                          смерти<sup>5</sup>.
                        </li>
                        <li>
                          Для пациентов с ХСН I-III ФК, находящихся в состоянии
                          эуволемии, пероральное назначение
                          бета-адреноблокаторов рекомендуется в качестве первой
                          линии терапии для контроля частоты желудочковых
                          сокращений<sup>5</sup>.
                        </li>
                        <li>
                          Применение амиодарона рекомендовано у пациентов с
                          пароксизмальной формой ФП в случае неэффективности
                          терапии бета-адреноблокаторами для контроля
                          симптомов<sup>5</sup>.
                        </li>
                      </ul>
                    </div>
                    <div class="mb-4">
                      <b>Агонисты имидазолиновых рецепторов</b>
                    </div>
                    <div class="mb-8">
                      <ul>
                        <li>
                          Моксонидин для лечения АГ рекомендуется пациентам с МС
                          или ожирением в комбинации с ИАПФ, БРА, АК и
                          диуретиками при недостаточной эффективности
                          классических комбинаций<sup>4</sup>.
                        </li>
                      </ul>
                    </div>
                    <div class="mb-4">
                      <b>Дигоксин</b>
                    </div>
                    <div class="mb-8">
                      <ul>
                        <li>
                          Для пациентов с ХСН I-III ФК класса рекомендуется
                          применение дигоксина в тех случаях, когда ЧСЖ остается
                          высокой несмотря на применение бета-адреноблокаторов
                          или при невозможности назначения бета-адреноблокаторов
                          для контроля ЧСЖ<sup>5</sup>.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <RadioButton
                class="mt-6 mb-2"
                :inputValue="'test23_1_1'"
                v-model="radioTabs"
                :success="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Дапаглифлозин'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_2'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Дигоксин'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_3'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Амлодипин'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_4'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Лозартан'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_5'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Моксонидин'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_6'"
                v-model="radioTabs"
                :success="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Периндоприл (продолжить в прежней дозе)'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_7'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Верошпирон'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_8'"
                v-model="radioTabs"
                :success="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Индапамид (продолжить в прежней дозе)'"
                @change="answerTest"
              />
              <RadioButton
                class="mb-2"
                :inputValue="'test23_1_9'"
                v-model="radioTabs"
                :error="radioTabs !== null"
                :disabled="radioTabs !== null"
                :label="'Торасемид'"
                @change="answerTest"
              />
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${radioTabs == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs != null"
            >
              <div
                v-if="radioTabs == 'test23_1_1' || radioTabs == 'test23_1_6' || radioTabs == 'test23_1_8'"
                class="title-1 mb-4 d-flex"
                style="color: #6bc399"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/green_check2.png')
                  "
                />
                Правильный ответ!
              </div>
              <div
                v-if="radioTabs != 'test23_1_1' && radioTabs != 'test23_1_6' && radioTabs != 'test23_1_8'"
                class="title-1 mb-4 d-flex"
                style="color: #cf1517"
              >
                <img
                  class="mr-4"
                  :src="
                    require('@/assets/img/interactive-clinical-case/red_warning.png')
                  "
                />
                Неверный ответ!
              </div>
              <div>
                <div class="mb-4">
                  <video
                    src="https://storage.yandexcloud.net/az-most.ru-storage/media/content/interactive-clinical-case/expert/9.mp4"
                    :poster="
                      require('@/assets/img/interactive-clinical-case/step0_video1.jpg')
                    "
                    controls
                  />
                </div>
                <div class="text-2 mb-4">
                  <div class="mb-4">
                    <b
                      >Выбор антигипертензивной терапии и лечения сердечной
                      недостаточности у коморбидного пациента</b
                    >
                  </div>

                  <div class="mb-8">
                    <ul>
                      <li>
                        Пациентам с диагностированной ФП и АГ рекомендовано
                        назначение ингибиторов АПФ или антагонистов ангиотензина
                        II в сочетании с ААТ с целью снижения вероятности
                        рецидивов ФП<sup>1</sup>.
                      </li>
                      <li>
                        Назначение ингибиторов АПФ и антагонистов ангиотензина
                        II (АРА) рекомендовано с целью профилактики ФП пациентам
                        с АГ, особенно при наличии гипертрофии ЛЖ<sup>1</sup>.
                      </li>
                      <li>
                        Всем пациентам с АГ (кроме пациентов низкого риска с
                        АД&lt;150/90 мм рт. ст., пациентов ≥80 лет, пациентов с
                        синдромом старческой астении) в качестве стартовой
                        терапии рекомендована комбинация антигипертензивных
                        препаратов, предпочтительно фиксированная, для улучшения
                        приверженности к терапии. Предпочтительные комбинации
                        должны включать блокатор ренин-ангиотензиновой системы
                        (РААС) (ингибитор АПФ или АРА) и дигидропиридиновый
                        антагонист кальция (АК) или диуретик<sup>4</sup>.
                      </li>
                      <li>
                        Всем пациентам с АГ и ХБП в качестве начальной терапии
                        рекомендуется комбинация блокатора РААС с АК или
                        диуретиком в связи с наилучшим влиянием данных
                        комбинаций на частоту достижения целевого АД и снижение
                        СС риска<sup>4</sup>.
                      </li>
                      <li>
                        Всем пациентам с АГ в сочетании с атеросклерозом
                        периферических артерий в качестве начальной терапии
                        рекомендуется назначать комбинацию блокатора РААС и АК
                        или диуретика<sup>4</sup>.
                      </li>
                      <li>
                        У пациентов со стабильной ИБС при сопутствующих
                        заболеваниях, имеющих существенное значение для прогноза
                        (постинфарктный кардиосклероз, АГ, СД, СН), для
                        профилактики ССО рекомендуется назначать иАПФ или
                        АРА<sup>3</sup>.
                      </li>
                      <li>
                        Пациентам с ХСН рекомендована терапия иАПФ/АРА/
                        валсартан+сакубитрил, бета-адреноблокаторами и
                        альдостерона антагонистами в том числе для снижения
                        частоты возникновения ФП<sup>5</sup>.
                      </li>
                      <li>
                        У пациентов, не достигших целевого АД при приеме моно-
                        или комбинированной АГТ, не включавшей диуретики,
                        рекомендуется назначение низких доз тиазидных или
                        тиазидоподобных диуретиков в составе комбинированной
                        терапии с АРА, ИАПФ и АК для усиления гипотензивного
                        эффекта и достижения целевого АД<sup>4</sup>.
                      </li>
                      <li>
                        Пациентам с СНсФВ и застойными явлениями рекомендованы
                        диуретики с целью устранения застойных явлений и
                        уменьшения выраженности симптомов и признаков сердечной
                        недостаточности<sup>5</sup>.
                      </li>
                      <li>
                        У пациентов с СНсФВ в эуволюмическом статусе, имеющих
                        выраженные функциональные ограничения и тяжёлую
                        диастолическую дисфункцию левого желудочка рекомендуются
                        диуретики для улучшения клинической симптоматики
                        СН<sup>5</sup>.
                      </li>
                      <li>
                        Тиазидные или петлевые диуретики рекомендуются для
                        усиления гипотензивной терапии при недостаточной
                        антигипертензивной эффективности применения комбинации
                        иАПФ (АРА вместо иАПФ, но не вместе!),
                        бета-адреноблокаторов и альдостерона антагонистов у
                        пациентов с ХСН и артериальной гипертонией<sup>1</sup>.
                      </li>
                      <li>
                        Альдостерона антагонисты рекомендуются всем пациентам с
                        ХСН II-IV ФК и ФВ ЛЖ ≤ 40%, для снижения риска
                        госпитализации из-за СН и смерти<sup>5</sup>.
                      </li>
                      <li>
                        У пациентов с СН c умеренно сниженной или сохраненной
                        ФВ, включенных в исследование DELIVER, дапаглифлозин
                        снижал комбинированный риск сердечно-сосудистой смерти
                        или ухудшения событий сердечной недостаточности во всем
                        возрастном спектре, с постоянным профилем
                        безопасности<sup>23</sup>.
                      </li>
                      <li>
                        Ингибиторы натрий-глюкозного котранспортера 2 типа
                        обладают целым рядом прямых и опосредованных кардио- и
                        ренопротективных эффектов, обеспечивающих эффективность
                        их применения при ХСН, в т.ч. у больных с
                        ХБП<sup>24</sup>.
                      </li>
                      <li>
                        Только класс ингибиторов иНГЛТ-2 улучшает как прогноз,
                        так и качество жизни у пациентов с ХСНсФВ. АРНИ и АМКР
                        продемонстрировали эффективность только при
                        сопутствующей резистентной АГ. Кроме того, у ряда
                        пациентов может быть рассмотрено назначение комбинации
                        бета-блокаторов и селективного блокатора If-каналов
                        синусового узла при ЧСС > 70 уд/мин и синусовом ритме и
                        при сопутствующей ИБС. Остальные группы препаратов
                        значимо не влияют на качество жизни и прогноз пациентов
                        с ХСНсФВ и могут назначаться только при наличии других
                        показаний<sup>25</sup>.
                      </li>
                    </ul>
                  </div>
                  <div class="mb-4">
                    <b
                      >Место бета-адреноблокаторов в терапии коморбидного
                      пациента</b
                    >
                  </div>
                  <div class="mb-8">
                    <ul>
                      <li>
                        При стабильной стенокардии I–II ФК и ЧСС>60 уд/мин
                        рекомендуется назначить в качестве препарата 1-й линии
                        бета-адреноблокатор или недигидропиридиновые блокаторы
                        «медленных» кальциевых каналов (верапамил или дилтиазем)
                        для снижения ЧСС до значений 55–60 уд/мин<sup>3</sup>.
                      </li>
                      <li>
                        Бета-адреноблокаторы рекомендованы в качестве
                        антигипертензивной терапии при наличии особых
                        клинических ситуаций: например, стенокардии,
                        перенесенного инфаркта миокарда, сердечной
                        недостаточности<sup>4</sup>.
                      </li>
                      <li>
                        Пациентам с АГ и симптомами стенокардии рекомендуется
                        назначение ББ и/или АК<sup>4</sup>.
                      </li>
                      <li>
                        Пациентам с АГ и ФП при необходимости контроля ЧСС в
                        качестве компонента гипотензивной терапии рекомендуется
                        назначать бета-адреноблокаторы или недигидропиридиновые
                        антагонисты кальция<sup>4</sup>.
                      </li>
                      <li>
                        Бета-адреноблокаторы рекомендуются в качестве препаратов
                        1-й линии антиангинальных препаратов у пациентов с ХСН и
                        стенокардией напряжения в связи с их способностью не
                        только уменьшать симптомы стенокардии, но и снижать риск
                        госпитализаций из-за СН и риск смерти<sup>5</sup>.
                      </li>
                      <li>
                        Для пациентов с ХСН I-III ФК, находящихся в состоянии
                        эуволемии, пероральное назначение бета-адреноблокаторов
                        рекомендуется в качестве первой линии терапии для
                        контроля частоты желудочковых сокращений<sup>5</sup>.
                      </li>
                      <li>
                        Применение амиодарона рекомендовано у пациентов с
                        пароксизмальной формой ФП в случае неэффективности
                        терапии бета-адреноблокаторами для контроля
                        симптомов<sup>5</sup>.
                      </li>
                    </ul>
                  </div>
                  <div class="mb-4">
                    <b>Агонисты имидазолиновых рецепторов</b>
                  </div>
                  <div class="mb-8">
                    <ul>
                      <li>
                        Моксонидин для лечения АГ рекомендуется пациентам с МС
                        или ожирением в комбинации с ИАПФ, БРА, АК и диуретиками
                        при недостаточной эффективности классических
                        комбинаций<sup>4</sup>.
                      </li>
                    </ul>
                  </div>
                  <div class="mb-4">
                    <b>Дигоксин</b>
                  </div>
                  <div class="mb-8">
                    <ul>
                      <li>
                        Для пациентов с ХСН I-III ФК класса рекомендуется
                        применение дигоксина в тех случаях, когда ЧСЖ остается
                        высокой несмотря на применение бета-адреноблокаторов или
                        при невозможности назначения бета-адреноблокаторов для
                        контроля ЧСЖ<sup>5</sup>.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :disableNext="radioTabs == null"
        :stepsCount="18"
        :activeStep="16"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
export default {
  name: "ICCStep22",
  components: {
    Steps,
    RadioButton,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: null,
  }),
  methods: {
    answerTest() {
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
  },
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices.find(
      (a) => a.is_selected
    )
      ? this.step.test.questions[0].choices.find((a) => a.is_selected).slug
      : null;
  },
  watch: {
    radioTabs() {
      if (!this.step.test.questions[0].is_answered && this.radioTabs) {
        let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: [this.radioTabs],
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>