<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title">
              Выберите скрининговые шкалы, которые помогут Вам выбрать дальнейшую тактику?
            </div>
            <div class="d-none d-xl-block">
              <RadioButton
                class="mt-6 mb-2 radiobtn_simple"
                :inputValue="0"
                v-model="radioTabs[0]"
                :label="'EHRA'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="1"
                v-model="radioTabs[0]"
                :label="'Шкала H2FPEF'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="2"
                v-model="radioTabs[0]"
                :label="'ШОКС'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="3"
                v-model="radioTabs[0]"
                :label="'SCORE2'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="4"
                v-model="radioTabs[0]"
                :label="'Шкала CHA2DS2-VASc'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="5"
                v-model="radioTabs[0]"
                :label="'Шкала HAS-BLED'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="6"
                v-model="radioTabs[0]"
                :label="'Шкала SAMe-TT2R2'"
              />
              <RadioButton
                class="mb-2 radiobtn_simple"
                :inputValue="7"
                v-model="radioTabs[0]"
                :label="'Госпитальная Шкала Тревоги и Депрессии (HADS)'"
              />
            </div>
            <div class="d-xl-none">
              <Select
                :placeholder="'Выберите результат'"
                :items="list"
                :multiple="false"
                v-model="radioTabs"
                :searchable="false"
              >
                <template #list-header>
                  <div class="select-form__select-header">
                    Выберите результат
                  </div>
                </template>
              </Select>
            </div>
          </div>
          <div
            class="icc__block-white-plate"
            :style="`opacity: ${radioTabs[0] == null ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 0"
            >
              <div class="title-1 mb-4">EHRA</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table1.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table1.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 1"
            >
              <div class="title-1 mb-4">Шкала H2FPEF</div>
              <div class="text-2 mb-4">6 баллов (высокая степень достоверности СНсФВ)</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table2.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table2.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 2"
            >
              <div class="title-1 mb-4">ШОКС</div>
              <div class="text-2 mb-4">2 балла (1 фк.)</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table3.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table3.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 3"
            >
              <div class="title-1 mb-4">SCORE2</div>
              <div class="text-2 mb-4">Оценка риска сердечно-сосудистых осложнений</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table4.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table4.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 4"
            >
              <div class="title-1 mb-4">Шкала CHA2DS2-VASc</div>
              <div class="text-2 mb-4">4 балла</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table5.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table5.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 5"
            >
              <div class="title-1 mb-4">Шкала HAS-BLED</div>
              <div class="text-2 mb-4">0 баллов</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table6.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table6.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 6"
            >
              <div class="title-1 mb-4">Шкала SAMe-TT2R2</div>
              <div class="text-2 mb-4">3 балла. Предпочтительно лечение новыми пероральными антикоагулянтами (НОАК)</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table7.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table7.jpg')} 3x`"
                />
              </div>
            </div>
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
              v-if="radioTabs[0] == 7"
            >
              <div class="title-1 mb-4">Госпитальная Шкала Тревоги и Депрессии (HADS)</div>
              <div class="full-screen mb-4">
                <div
                  class="full-screen__trigger"
                  :data-coolbox="
                    require('@/assets/img/interactive-clinical-case/step5__table8.jpg')
                  "
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="40" height="40" rx="8" fill="#830051"></rect>
                    <path
                      d="M23 11H29V17"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M17 29H11V23"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M29 11L22 18"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                    <path
                      d="M11 29L18 22"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></path>
                  </svg>
                </div>

                <img
                  :srcset="`${require('@/assets/img/interactive-clinical-case/step5__table8.jpg')} 3x`"
                />
              </div>
            </div>
            
            
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :stepsCount="18"
        :activeStep="6"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import RadioButton from "@/components/interactiveClinicalCase/RadioButton.vue";
import Select from "@/components/interactiveClinicalCase/Select.vue";
export default {
  name: "ICCStep5",
  components: {
    Steps,
    RadioButton,
    Select,
  },
  data: () => ({
    radioTabs: [],
  }),
  computed: {
    list() {
      return [
        { label: "EHRA", value: 0 },
        { label: "Шкала H2FPEF", value: 1 },
        { label: "ШОКС", value: 2 },
        { label: "SCORE2", value: 3 },
        { label: "Шкала CHA2DS2-VASc", value: 4 },
        { label: "Шкала HAS-BLED", value: 5 },
        { label: "Шкала SAMe-TT2R2", value: 6 },
        { label: "Госпитальная Шкала Тревоги и Депрессии (HADS)", value: 7 },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";

.icc {
  & .select-form {
    &__select-header {
      margin-bottom: 16px;
      padding: 10px 16px;
      border-radius: 8px;
      background: #f8f8f8;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      text-align: left;
      color: #3c4242;
    }
  }
}
</style>