<template>
  <div class="icc__block">
    <div class="icc__block-body">
      <div class="icc__block-container icc__block-overflow pb-4">
        <div class="icc__block-row">
          <div class="icc__block-text">
            <div class="icc__block-title mb-2">
              Укажите осложнение основного диагноза
            </div>
            <div class="text-2 mb-6" style="color: #830051">
              (укажите один или несколько вариантов ответа)
            </div>
            <div
              class="
                icc__block-white-plate icc__block-white-plate_mobile
                d-xl-none
              "
              v-if="answered"
            >
              <div
                class="d-flex direction-column icc__block-white-plate-overflow"
              >
                <div class="title-1 mb-4">
                  При обследовании выявлены осложнения основного диагноза:
                </div>
                <div class="text-2 mb-2">
                  Пароксизмальная форма фибрилляции предсердий, вне пароксизма.
                  EHRA 2a. CHA2DS2-VASc – 4 балла. HAS-BLED – 0 баллов.
                </div>
                <div class="text-2">
                  Хроническая сердечная недостаточность с сохраненной ФВ (60%)
                  2а стадии, 1 фк по NYHA
                </div>
              </div>
            </div>
            <div>
              <Checkbox
                class="mt-6 mb-2"
                :inputValue="'test11_1_1'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Осложнений у пациентки не выявлено'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test11_1_2'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Постоянная форма фибрилляции предсердий, нормосистолия. EHRA 2a. CHA2DS2-VASc – 4 балла. <br>HAS-BLED – 0 баллов.'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test11_1_3'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Пароксизмальная форма фибрилляции предсердий, вне пароксизма. EHRA 2a. CHA2DS2-VASc – 4 балла. <br>HAS-BLED – 0 баллов.'"
              />
              <Checkbox
                class="mb-2"
                :inputValue="'test11_1_4'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Хроническая сердечная недостаточность с сохраненной ФВ (60%) 2а стадии, 1 фк по NYHA'"
              />
              <Checkbox
                class="mb-6"
                :inputValue="'test11_1_5'"
                v-model="radioTabs"
                :disabled="answered"
                :label="'Хроническая сердечная недостаточность с сохраненной ФВ (60%) 2а стадии, 2 фк по NYHA'"
              />
              <div
                @click="answerTest"
                :disabled="!radioTabs.length"
                :class="{ button_disabled: !radioTabs.length }"
                v-if="!answered"
                class="button button_pink-light px-6 mb-4"
              >
                Ответить
              </div>
            </div>
          </div>
          <div
            class="icc__block-white-plate d-none d-xl-flex"
            :style="`opacity: ${!answered ? '0' : '1'}`"
          >
            <div
              class="d-flex direction-column icc__block-white-plate-overflow"
            >
              <div class="title-1 mb-4">
                При обследовании выявлены осложнения основного диагноза:
              </div>
              <div class="text-2 mb-2">
                Пароксизмальная форма фибрилляции предсердий, вне пароксизма.
                EHRA 2a. CHA2DS2-VASc – 4 балла. HAS-BLED – 0 баллов.
              </div>
              <div class="text-2">
                Хроническая сердечная недостаточность с сохраненной ФВ (60%) 2а
                стадии, 1 фк по NYHA
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="icc__block-steps">
      <Steps
        @prevStep="$emit('prevStep')"
        @nextStep="$emit('nextStep')"
        :disableNext="!answered"
        :stepsCount="18"
        :activeStep="11"
      />
    </div>
  </div>
</template>

<script>
import Steps from "@/components/interactiveClinicalCase/Steps.vue";
import Checkbox from "@/components/interactiveClinicalCase/Checkbox.vue";
export default {
  name: "ICCStep13",
  components: {
    Steps,
    Checkbox,
  },
  props: {
    step: Object,
  },
  data: () => ({
    radioTabs: [],
    answered: false,
  }),
  methods: {
    answerTest() {
      this.answered = true;
      if (!this.step.test.questions[0].is_answered && this.radioTabs.length) {
         let params = new URLSearchParams();
        if (this.$route.query.access) {
          params.append("access", this.$route.query.access);
        }
        this.$axios({
          url: `/api/clinical-case/test_answer`,
          params: params,
          method: "POST",
          data: {
            test_result: this.step.test_result_id,
            question: this.step.test.questions[0].slug,
            choices: this.radioTabs,
          },
        })
          .then((res) => {})
          .catch((error) => {});
      }
      this.$el.querySelector(".icc__block-container").scrollTo(0, 0);
    },
  },
  mounted() {
    this.radioTabs = this.step.test.questions[0].choices
      .filter((a) => a.is_selected)
      .map((a) => a.slug);
    if (this.radioTabs.length) {
      this.answered = true;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/icc_detail.scss";
</style>